<template>
  <div id="menu" class="mt-5">
    <div class="container">
      <!-- Title -->
      <div class="row mb-5">
        <div class="col">
          <h1>menu régulier</h1>
        </div>
      </div>

      <!-- Menu -->
      <div class="row">
        <div class="col-lg-8 col-xl-9">
          <MenuTwoColumnsSection class="border-bottom pb-4" :menu="menu.grilledCheeses" />
          <div class="row align-items-center justify-content-center mb-5">
            <div class="col-6 col-sm-4 col-lg-3 col-xl-2 mb-2">
              <div class="circle">
                <div>duo<br>chips<br>+ 2 $</div>
              </div>
            </div>
            <div class="col-6 col-sm-4 col-lg-3 col-xl-2 offset-xl-1 mb-2">
              <div class="circle">
                <div>duo<br>salade<br>+ 3 $</div>
              </div>
            </div>
          </div>

          <div class="row mb-n5">
            <div class="col-md-6 mb-5">
              <Coffees />
            </div>
            <div class="col-md-6 mb-5">
              <MenuOneColumnSection :menu="menu.breakfasts" />
              <MenuOneColumnSection :menu="menu.desserts" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-3">
          <div class="row mb-n4">
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/day/breaking_cheese.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/day/cheese_pan.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/day/counter.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/day/plate_cheese.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <div class="menu-allergens">
                <div class="row mb-2">
                  <div class="col">
                    <font-awesome-icon class="fa-2x text-white" :icon="['fa', 'exclamation-triangle']" />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    Nos aliments peuvent contenir des allergènes. Faites-nous part de vos allergies.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/day/glasses.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/day/parmesen.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/day/teas.jpg" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menu from "@/json/menu-day.json";
  import Coffees from "@/components/menu/Coffees.vue";
  import MenuOneColumnSection from "@/components/menu/MenuOneColumnSection.vue";
  import MenuTwoColumnsSection from "@/components/menu/MenuTwoColumnsSection.vue";

  export default {
    name: 'DayMenu',
    data() {
      return {
        menu
      }
    },
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    components: {
      Coffees,
      MenuOneColumnSection,
      MenuTwoColumnsSection,
    },
  }
</script>

<style lang="scss" scoped>
.circle {
  background-color: $primary;
  border-radius: 50%;
  color: white;
  font-family: 'Righteous', cursive;
  font-size: 22px;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.circle > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>

<template>
  <div id="menu-cantine" class="mt-5">
    <div class="container">
      <div id="hero" class="carousel slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#hero" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-none d-md-block w-100" src="@/assets/home/carousel/grilled_cheese.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/grilled_cheese_320px.jpg">
          </div>
          <!-- <div class="carousel-item">
            <img class="d-none d-md-block w-100" src="@/assets/home/carousel/2.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/2_mobile.jpg">
          </div>
          <div class="carousel-item">
            <a href="https://order.koomi.com/la-base-V0rjQMkeDy/fr">
              <img class="d-none d-md-block w-100" src="@/assets/home/carousel/3.png">
              <img class="d-block d-md-none w-100" src="@/assets/home/carousel/3_mobile.png">
            </a>
          </div> -->
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-8 mt-5">
          <h1 class="mb-4">La base le midi, les grilled cheese, les burgers et les options végés sont à l’honneur à Verdun.</h1>
          <p>Un lunch rapide de qualité et fait maison ça vous dit? À la base, nous avons créé un menu inspiré du street 
            food du monde entier. Un grilled cheese et une petite salade… Un burger gourmand et des frites… des sandwichs, 
            salades et burgers végé ou végane pour répondre à tous vos choix nutritionnels.
          </p>
        </div>
      </div>

      <!-- Menu -->
      <div class="row mt-5">
        <div class="col-md-6 px-sm-3 pe-lg-5">
          <MenuOneColumnSection :hasSeparator="true"  :menu="menu.grilledCheeses" />
        </div>
        <div class="col-md-6 px-sm-3 ps-lg-5">
          <Sapori :hasSeparator="true"  :menu="sapori.mambo" />
        </div>
      </div>
      <hr class="mb-5">
      <div class="row mt-5 pt-4">
        <div class="col-md-6 px-sm-3 pe-lg-5">
          <CoffeesAndPastries :hasSeparator="false" :menu="coffee.soups" />
        </div>
        <div class="col-md-6 px-sm-3 ps-lg-5">
          <CoffeesAndPastries :hasSeparator="false" :menu="coffee.pastriesB" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menu from "@/json/menu-day.json";
  import sapori from "@/json/menu-mambo.json";
  import coffee from "@/json/menu-coffee.json";
  import MenuOneColumnSection from "@/components/menu/MenuOneColumnSection.vue";
  import Sapori from "@/components/menu/Sapori.vue";
  import CoffeesAndPastries from "@/components/menu/CoffeesAndPastries.vue";

  export default {
    name: 'MenuCantine',
    data() {
      return {
        menu,
        sapori,
        coffee,
      }
    },
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    components: {
      MenuOneColumnSection,
      Sapori,
      CoffeesAndPastries
    },
  }
</script>

<style lang="scss" scoped>
.circle {
  background-color: $primary;
  border-radius: 50%;
  color: white;
  font-family: 'Righteous', cursive;
  font-size: 22px;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.circle > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.carousel-inner {
  margin-top: 170px;
}
</style>

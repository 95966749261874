<template>
    <div id="milks" class="mb-sm-2 mb-lg-4">
        <div class="row mb-2">
            <div class="col">
                <h2 v-html="menu.title"></h2>
            </div>
        </div>
        <div class="row">
            <template v-for="(item, i) in menu.items">
                <div class="col-6 col-sm-3 col-md-2 col-lg-1 text-center py-3" :key="i">
                    <span v-html="item.name" v-if="item.name"></span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Milks',
    props: [ "menu" ],
}
</script>

<style lang="scss" scoped>

</style>
<template>
    <div id="menu-mambo" class="mb-5" :class="[$route.name.includes('MenuNight') ? 'dark-theme' : 'light-theme']">
    <!-- Icon -->
    <div class="row mb-1" v-if="isNight">
        <div class="col text-end">
            <img src="@/assets/icons/lantern.svg" class="me-3" width="30" />
        </div>
    </div>

    <!-- Title -->
    <div class="row mb-4" v-if="menu.title">
        <div class="col">
            <h2 class="pacman-title">
                <span v-html="menu.title"></span>
            </h2>
        </div>
    </div>

    <!-- Paragraph -->
    <div class="row mb-4" v-if="menu.paragraph">
        <div class="col">
            <div class="" v-html="menu.paragraph"></div>
        </div>
    </div>

    <!-- Items -->
    <div class="menu" v-if="menu.items">
        <template v-for="(item, i) in menu.items">
            <div class="d-flex px-lg-5 pb-5 px-3" :class="getClass(i, menu.items.length)" :key="i">
                <div class="row flex-column flex-grow-1 ps-4">
                    <div  v-if="item.name">
                        <h3 class="name-dark" v-html="item.name"></h3>
                    </div>            
                    <div class="menu-description" v-if="item.shortDescription">
                        <span v-html="item.shortDescription"></span>
                    </div>
                </div>
                <div>
                    <!-- Price -->
                    <div class="h5">
                        <span v-html="item.price + '&nbsp;$'" v-if="item.price"></span>
                        <span class="d-inline-flex align-items-center me-4" v-if="item.mediumSizePrice">
                            <small class="size">m</small>
                            <span>&nbsp;</span>
                            <span v-html="item.mediumSizePrice + '&nbsp;$'"></span>
                        </span>
                        <span class="d-inline-flex align-items-center" v-if="item.largeSizePrice">
                            <small class="size">g</small>
                            <span>&nbsp;</span>
                            <span v-html="item.largeSizePrice + '&nbsp;$'"></span>
                        </span>
                    </div>
                    <!-- Vegetarian -->
                    <div class="row mt-2" v-if="item.isVegetarian">
                        <div class="col text-center">
                            <img src="@/assets/icons/vegetarian.svg" width="50" />
                        </div>
                    </div>
                </div>
                
            </div>
        </template>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="line orange-line"></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Sapori',
    props: [ "hasSeparator", "isNight", "menu" ],
    methods: {
    getClass(index, arraySize) {
        const classes = [];

        if (this.hasSeparator && index < arraySize - 1) {
            classes.push('menu-item');
        }

        if (!this.hasSeparator && index < arraySize - 2) {
            classes.push('mb-2');
        }

        return classes.join(" ");
    },
    }
}
</script>

<style lang="scss" scoped>

h2.pacman-dark-title::after {
    z-index: 300;
}

.menu-description span {
    line-height: 1.6;
}

.name-dark {
    color: $dark !important;
}

.line {
    height: 15px;
    width: 100%;
}

.green-line {
    background-color: #03a54f;
}

.orange-line {
    background-color: $primary;
}

</style>
<template>
    <div id="menu" class="mt-5">
        <div class="container">
            <div class="row mb-5 title-container">
                <div class="col-lg-8 mt-5">
                    <h1 class="mb-4">Les montréalais sont fous du grilled cheese</h1>
                    <p>
                        Nul ne peut résister au fromage coulant surtout lorsqu'il provient de nos meilleurs producteurs au Québec. Laissez-vous tenter par un de nos grilled-cheese préparés avec un pain boulanger.
                    </p>
                </div>
            </div>
            <MenuBlock :menu="menu" />
        </div>
    </div>
</template>

<script>
import menu from "@/json/menu-grilledcheese.json";
import MenuBlock from "@/components/menu/MenuBlock.vue";

export default {
    name : "MenuGrilledCheese",
    data() {
        return {
            menu
        }
    },
    components : {
        MenuBlock,
    }
}
</script>

<style lang="scss" scoped>
</style>
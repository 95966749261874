<template>
    <div id="menu" class="mt-5">
        <div class="container">
            <div class="row mb-5 title-container">
                <div class="col-lg-8 mt-5">
                    <h1 class="mb-4">Les burgers à découvrir à Verdun</h1>
                    <p>
                        Venez goûter aux burgers façon la base. À chaque saison, nous créons des nouvelles recettes du fameux sandwich 
                        au boeuf, au poulet, végé et au porc braisé. Pain de boulanger, sauces secrètes avec nos propres mélange 
                        d'épices...tout y est pour vous faire redécouvrir le burger. 
                    </p>
                </div>
            </div>
            <MenuBlock :menu="menu" />
        </div>
    </div>
</template>

<script>
import menu from "@/json/menu-secretburgers.json";
import MenuBlock from "@/components/menu/MenuBlock.vue";

export default {
    name : "MenuSercretBurgers",
    data() {
        return {
            menu
        }
    },
    components : {
        MenuBlock,
    }
}
</script>

<style lang="scss" scoped>
</style>

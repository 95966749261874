<template>
    <div id="footer" class="mt-5">
        <hr class="mb-5">
        <div class="container">
            <div class="row footer-items d-flex flex-wrap flex-sm-column justify-content-sm-around align-items-sm-stretch flex-md-row align-items-md-center justify-content-md-center mb-lg-5">
                <div class="d-flex col-sm-12 col-lg-1 me-lg-4">
                    <img  class="mx-auto" src="@/assets/logo.svg" width="85" />
                </div>
                <div class="d-flex col-sm-12 col-lg-2">
                    <div class="fs-5 fw-bold text-primary mx-auto">
                    5898 rue de Verdun,<br>
                    Verdun, Québec,<br>
                    H4H 1M4
                    </div>
                </div>
                <div class="col col-lg-6 pb-md-5 pb-lg-0">
                    <NaviCta />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NaviCta from "@/components/menu/NaviCta.vue";

export default {
    name: "Footer",
    components: {
        NaviCta,
    },
}
</script>

<style lang="scss" scoped>


@include media-breakpoint-down(md) {


}

@include media-breakpoint-down(sm) {
.footer-items {
    min-height: 380px;
}

}

</style>

<template>
    <div id="menu" class="mt-5">
        <div class="container">
            <div class="row mb-5 title-container">
                <div class="col-lg-8 mt-5">
                    <h1 class="mb-4">Verdun Végé-Végane, des options disponibles à la base</h1>
                    <p>
                        Nos menus cantine, buvette et brunch week-end proposent des options végé et véganes pour répondre aux choix nutritionnels de chacun.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 px-sm-3 pe-lg-5">
                    <CoffeesAndPastries :menu="menu.vege" />
                </div>
                <div class="col-lg-6 px-sm-3 pe-lg-5">
                    <CoffeesAndPastries :menu="menu.vegan" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import menu from "@/json/menu-vege.json";
import CoffeesAndPastries from "@/components/menu/CoffeesAndPastries.vue";

export default {
    name : "MenuVege",
    data() {
        return {
            menu,
        }
    },
    components : {
        CoffeesAndPastries,
    }
}
</script>

<style lang="scss" scoped>
</style>
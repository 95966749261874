<template>
    <div id="iced-beverages" class="mb-5">
        <!-- Title -->
        <div class="row mb-4" v-if="menu.title">
            <div class="col">
                <h2 class="pacman-title text-white">
                <span v-html="menu.title"></span>
                </h2>
            </div>
        </div> 
        <!-- Items -->
        <div class="menu" v-if="menu.categories">
            <template v-for="(category, c) in menu.categories">
                <div :class="getCategoryClass(c, menu.categories.length)" :key="c">
                    <div class="row mb-3" v-if="category.categoryName">
                        <div class="col px-lg-5">
                            <h3 class="iced-title" v-html="category.categoryName"></h3>
                        </div>
                    </div>
                    <div v-if="category.items">
                        <template v-for="(item, i) in category.items">
                            <div :class="getClass(i, category.items.length)" :key="i">
                                <div class="row" v-if="item.name">
                                    <div class="col d-flex justify-content-between px-sm-2 px-lg-5">
                                        <h5 v-html="item.name"></h5>
                                        <div class="h5">
                                            <h5 v-html="item.price + '&nbsp;$'" v-if="item.price"></h5>
                                            <span class="d-inline-flex align-items-center pe-lg-4" v-if="item.smallSizePrice">
                                                <small class="size">p</small>
                                                <span>&nbsp;</span>
                                                <span v-html="item.smallSizePrice + '&nbsp;$'"></span>
                                            </span>
                                            <span class="d-inline-flex align-items-center pe-lg-4" v-if="item.mediumSizePrice">
                                                <small class="size">m</small>
                                                <span>&nbsp;</span>
                                                <span v-html="item.mediumSizePrice + '&nbsp;$'"></span>
                                            </span>
                                            <span class="d-inline-flex align-items-center ps-2" v-if="item.largeSizePrice">
                                                <small class="size">g</small>
                                                <span>&nbsp;</span>
                                                <span v-html="item.largeSizePrice + '&nbsp;$'"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row d-flex flex-column align-items-start" v-if="item.addedExtra">
                                        <div class="col px-sm-2 px-lg-5 mb-2">
                                            <h6 class="addedExtra" v-html="item.addedExtra"><span>&nbsp;</span></h6>    
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'IcedBeverages',
    props:  [ "hasSeparator", "menu"],
    methods: {
        getClass(index, arraySize) {
            const classes = [];

            if (this.hasSeparator) {
               classes.push('menu-item'); 
            }

            if (!this.hasSeparator && index < arraySize - 1) {
                classes.push('mb-2');
            }

            return classes.join(" ");
        },
        getCategoryClass(index, arraySize) {
            const classes = [];
            if (!this.hasSeparator && index < arraySize - 1) {
                classes.push('mb-4');
            }

            return classes.join(" ");
        }
      }
    }
</script>

<style lang="scss" scoped>

</style>
import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";

import FindUs from "@/views/FindUs.vue";
import Home from "@/views/Home.vue";
import Menu from "@/views/Menu.vue";
import MenuCoffee from "@/views/MenuCoffee.vue";
import MenuCantine from "@/views/MenuCantine.vue";
import MenuWeekEnd from "@/views/MenuWeekEnd.vue";
import MenuBuvette from "@/views/MenuBuvette.vue";
import MenuAlpes from "@/views/MenuAlpes.vue";
import MenuDay from "@/views/MenuDay.vue";
import MenuBrunch from "@/views/MenuBrunch.vue";
import MenuSecretBurgers from "@/views/MenuSecretBurgers.vue";
import MenuGrilledCheese from "@/views/MenuGrilledCheese.vue";
import MenuVege from "@/views/MenuVege.vue";
import MenuGaterie from "@/views/MenuGaterie.vue";
//import MenuWines from "@/views/MenuWines.vue";

Vue.use(Router);
Vue.use(Meta)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    /*
    {
      path: "/la-base-brunchee",
      name: "MenuBrunch",
      component: MenuBrunch,
    },
    */
    /*
    {
      path: "/la-base-la-nuit",
      name: "MenuNight",
      component: MenuNight,
    },
    */
    {
      path: "/menu",
      name: "Menu",
      component: Menu,
    },
    {
      path: "/menu-au-sommet-des-alpes",
      name: "MenuAlpes",
      component: MenuAlpes,
    },
    {
      path: "/menu-buvette-tapas",
      name: "MenuBuvette",
      component: MenuBuvette,
    },
    {
      path: "/menu-regulier",
      name: "MenuDay",
      component: MenuDay,
      alias: [ "/la-base-le-jour" ],
    },
    {
      path: "/menu-cafe",
      name: "MenuCoffee",
      component: MenuCoffee,
    },
    {
      path: "/menu-cantine",
      name: "MenuCantine",
      component: MenuCantine,
    },
    {
      path: "/menu-weekend",
      name: "MenuWeekEnd",
      component: MenuWeekEnd,
    },
    {
      path: "/menu-brunch",
      name: "MenuBrunch",
      component: MenuBrunch,
      alias: [ "/la-base-la-fin-de-semaine" ],
    },
    {
      path: "/menu-secret-burgers",
      name: "MenuSecretBurgers",
      component: MenuSecretBurgers,
    },
    {
      path: "/menu-grilled-cheese",
      name: "MenuGrilledCheese",
      component: MenuGrilledCheese,
    },
    {
      path: "/menu-vege",
      name: "MenuVege",
      component: MenuVege,
    },
    {
      path: "/menu-gaterie",
      name: "MenuGaterie",
      component: MenuGaterie,
    },
    {
      path: "/ou-nous-trouver",
      name: "FindUs",
      component: FindUs,
    },
    /*{
      path: "/menu-vins",
      name: "MenuVins",
      component: MenuWines,
    },*/
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        behavior: 'auto',
        selector: to.hash,
        offset: { x: 0, y: 75 }
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0, y: 0
      }
    }
  },
});

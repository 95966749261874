<template>
  <div id="menu-buvette" class="mt-5">
    <div class="container">
      <div id="hero" class="carousel slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#hero" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-none d-md-block w-100" src="@/assets/home/la_base_la_nuit_450px_haut.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/la_base_poulet_petits_pois_Neon_320px.jpg">
          </div>
          <!-- <div class="carousel-item">
            <img class="d-none d-md-block w-100" src="@/assets/home/carousel/2.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/2_mobile.jpg">
          </div>
          <div class="carousel-item">
            <a href="https://order.koomi.com/la-base-V0rjQMkeDy/fr">
              <img class="d-none d-md-block w-100" src="@/assets/home/carousel/3.png">
              <img class="d-block d-md-none w-100" src="@/assets/home/carousel/3_mobile.png">
            </a>
          </div> -->
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-8 mt-5">
          <h1 class="mb-4">Vous cherchez un 5 à 7 tapas à Verdun? Le jeudi et vendredi soir, on se rejoint à la base.</h1>
          <p>Ambiance cocktail-bar, musique festive, 2 terrasses à l'avant et à l'arrière pour vous accueillir. Vos 5 à 7 du week-end 
            commencent par un cocktail signature préparé avec des spiritueux "made in Montréal", ou encore un vin orange parfait pour la saison printemps-été.
          </p>
          <p>Vous avez une fringale? Découvrez notre menu mambo-mambo, des tapas et des options plus gourmandes inspirées de la 
            cuisine soleil du monde entier.
          </p>
        </div>
      </div>

      <!-- Menu -->
      <div class="row mt-5">
        <div class="col-md-6 px-sm-3 pe-lg-5">
          <Sapori :hasSeparator="true" :menu="sapori.tapas" />
        </div>
        <div class="col-md-6 px-sm-3 ps-lg-5">
          <Sapori :hasSeparator="true" :menu="sapori.mambo" />
        </div>
      </div>
      <!--
      <hr class="mb-5">
      <div class="row mt-5 pt-4">
        <div class="col-md-6 px-sm-3 pe-lg-5">
          <CoffeesAndPastries :hasSeparator="false" :menu="sapori.cocktails" />
        </div>
        <div class="col-md-6 px-sm-3 ps-lg-5">
          <BeersAndWines :hasSeparator="false" :menu="sapori.beers" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  import sapori from "@/json/menu-mambo.json";
  import brunches from "@/json/menu-brunch.json";
  import Sapori from "@/components/menu/Sapori.vue";
  //import CoffeesAndPastries from "@/components/menu/CoffeesAndPastries.vue";
  //import BeersAndWines from "@/components/menu/BeersAndWines.vue";

  export default {
    name: 'MenuBuvette',
    data() {
      return {
        sapori,
        brunches,
      }
    },
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    components: {
      Sapori,
      //CoffeesAndPastries,
      //BeersAndWines
    },
  }
</script>

<style lang="scss" scoped>

.circle {
  background-color: $primary;
  border-radius: 50%;
  color: white;
  font-family: 'Righteous', cursive;
  font-size: 22px;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.circle > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>

<template>
  <div id="home" class="my-5">
    <div class="container">
      <div id="hero" class="carousel slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#hero" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <router-link :to="{ name: 'MenuBuvette' }">
              <img class="d-none d-md-block w-100" src="@/assets/home/carousel/Carnaval_LaBase.png">
              <img class="d-block d-md-none w-100" src="@/assets/home/carousel/carnaval_site_web_mobile.png">
            </router-link>
          </div>
          <!-- <div class="carousel-item">
            <img class="d-none d-md-block w-100" src="@/assets/home/carousel/2.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/2_mobile.jpg">
          </div>
          <div class="carousel-item">
            <a href="https://order.koomi.com/la-base-V0rjQMkeDy/fr">
              <img class="d-none d-md-block w-100" src="@/assets/home/carousel/3.png">
              <img class="d-block d-md-none w-100" src="@/assets/home/carousel/3_mobile.png">
            </a>
          </div> -->
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-8 offset-md-2 mt-5">
          <h1 class="mb-4">On se retrouve à la Base</h1>
          <p>
            Café le matin, cantine le midi, buvette le soir, et brunch le dimanche. La Base vous propose ses savoureux cafés, ses grilled cheese gourmands, dont la Base New York, le dernier venu sur la liste. Découvrez le nouveau menu brunch du monde, un voyage à travers les saveurs d’ici et d’aiileurs! <br>On se retrouve à la Base!
          </p>
        </div>
      </div>
      <MenuBlock :menu="homeMenu" />
    </div>
  </div>
</template>

<script>
import homeMenu from "@/json/home-items";
import MenuBlock from "@/components/menu/MenuBlock.vue";

export default {
  name: "Home",
  data() {
    return {
      homeMenu,
    }
  },
  components: {
    MenuBlock,
  },
}
</script>

<style lang="scss" scoped>
.carousel-inner {
  margin-top: 170px;
}

</style>

import { render, staticRenderFns } from "./MenuCantine.vue?vue&type=template&id=3691debd&scoped=true&"
import script from "./MenuCantine.vue?vue&type=script&lang=js&"
export * from "./MenuCantine.vue?vue&type=script&lang=js&"
import style0 from "./MenuCantine.vue?vue&type=style&index=0&id=3691debd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3691debd",
  null
  
)

export default component.exports
<template>
    <div id="navi-cta">
        <div class="nav justify-content-lg-end">
            <div class="mobile-width d-inline-flex mx-auto ms-lg-0 me-lg-0 nav-item py-sm-2 py-lg-0">
                <a href="https://labase.cafe/ou-nous-trouver" target="_blank" class="nav-link align-self-center text-white mx-auto mb-2 mt-0 my-md-0 pe-lg-2 mx-lg-0">
                        <font-awesome-icon class="locator fa-lg" :icon="['fal', 'map-marker-alt']" />
                </a>
            </div>
            <div class="mobile-width d-inline-flex nav-item mx-auto ms-lg-0 me-lg-0 py-sm-2 py-lg-0">
                <a href="tel:5144652228" class="nav-link cta_lb fw-normal mx-auto my-2 my-md-0 px-lg-4 mx-lg-3">
                        RÉSERVATION <span class="fw-bold ps-2">514 465-2228</span> 
                </a>
            </div>
            <div class="mobile-width d-inline-flex nav-item py-sm-2 py-lg-0">
                <a href="https://order.koomi.com/la-base-V0rjQMkeDy/fr" target="_blank" class="nav-link cta_lb fw-normal align-items-center mx-auto my-3 my-md-0 ps-lg-4 ms-lg-3 me-lg-0">
                        <font-awesome-icon class="pe-2 fa-lg" :icon="['fal', 'shopping-basket']" /> COMMANDE EN LIGNE
                </a>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name: "NaviCta", 
}
</script>

<style lang="scss" scoped>

.locator {
    color: $secondary;
}

#navi-cta {
    display: block;
    // background-color: $primary;
    width: 100%;
}

  /*navbar red CTAs*/
 #navi-cta .cta_lb {
    color: white;
    background-color: $secondary;
    text-decoration: none;
  }

  @include media-breakpoint-down(md) {
.mobile-width {
    display: inline-block;
    width: 100vw;
}

}

  @include media-breakpoint-down(sm) {
.mobile-width {
    display: inline-block;
    width: 100vw;
}

}


</style>

<template>
    <div id="MenuBlock" class="mb-5">
        <div v-if="menu.title" class="row">
            <div class="col-lg-8">
                <h2 class="pacman-title text-white">
                    <span v-html="menu.title"></span>
                </h2>
            </div>
        </div>
        <div class="row mb-5">
        </div>
        <div class="row">
            <template v-for="(block, i) in menu.blocks">
                <div class="col-lg-4 p-5 pt-0" :key="i">
                    <div class="row">
                        <div class="item_grid block-row">
                            <router-link v-if="block.link" class="nav-link" :to="{ name: block.link }" @click.native="closeMenu">
                                <h4 v-if="block.title" v-html="block.title"></h4>  
                            </router-link>
                            <h4 v-else-if="block.title" v-html="block.title"></h4>              
                            <img v-if="block.image"  class="w-100" :src="require('@/assets/menu/' + block.image)" :alt="block.alt">
                        </div>
                    </div>
                    <div class="row">
                        <p v-if="block.description" class="block-desc m-0 mt-4">{{ block.description }}</p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {Collapse} from 'bootstrap';

export default {
    name: "ImageBlock",
    props: ["menu"],
    data() {
      return {
        bsCollapse: false
      }
    },
    mounted() {
      this.bsCollapse = new Collapse(document.querySelector('#navbarSupportedContent'), {
        toggle: false
      })
    },
    methods: {
        closeMenu() {
            this.bsCollapse.hide();
        }
    },
}

</script>

<style lang="scss" scoped>
    .block-row {
        padding: 0;
    }
    .block-desc {
        font-size: 1.25em;
    }

    .line {
        height: 15px;
        width: 100%;
    }

    .green-line {
        background-color: #03a54f;
    }
</style>

<template>
  <header>
      <div class="fixed-top">
        <div class="container relative-block">
          <div class="d-flex top-menu d-none d-lg-block justify-content-sm-center pt-2">
              <div class="col">
                  <NaviCta />
              </div>
          </div>
        </div>
        <nav ref="navbar container" class="navbar navbar-expand-lg py-lg-5">
          <div class="container d-flex align-items-center">
            <router-link class="navbar-brand" :to="{ name: 'Home' }" @click.native="closeMenu">
              b
            </router-link>
            <button ref="toggler" class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" v-if="getRouteName !== 'Menu'">
              <span class="nav-icon">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
            <div ref="close-menu" class="collapse navbar-collapse" id="navbarSupportedContent">
              <!-- Navigation principale -->
              <div class="d-flex align-items-center justify-content-center">
                <div class="row d-flex no-padding" id="navbarNav" v-if="getRouteName !== 'Menu'">
                  <ul class="navbar-nav m-auto mb-lg-0 align-items-center d-flex">
                    <li class="nav-item d-flex flex-wrap pe-2">
                      <router-link class="nav-link" :to="{ name: 'MenuCoffee' }" @click.native="closeMenu">
                        café
                      </router-link>
                    </li>
                    <li class="nav-item d-flex flex-wrap px-2">
                      <router-link class="nav-link" :to="{ name: 'MenuCantine' }" @click.native="closeMenu">
                        cantine
                      </router-link>
                    </li>
                    <li class="nav-item d-flex flex-wrap px-2">
                      <router-link class="nav-link" :to="{ name: 'MenuBuvette' }" @click.native="closeMenu">
                        buvette
                      </router-link>
                    </li>
                    <li class="nav-item d-flex flex-wrap ps-2 pe-2">
                      <router-link class="nav-link" :to="{ name: 'MenuWeekEnd' }" @click.native="closeMenu">
                        brunch week-end
                      </router-link>
                    </li>
                    <li class="d-none d-lg-block">
                      <span class="text-light fw-bolder">
                        |
                      </span>
                    </li>
                    <li class="nav-item d-flex flex-wrap ps-4 pe-2">
                      <router-link class="nav-link" :to="{ name: 'MenuSecretBurgers' }" @click.native="closeMenu">
                        <font-awesome-icon class="fa-lg" :icon="['fal', 'cheeseburger']" /> burgers inédits
                      </router-link>
                    </li>
                    <li class="nav-item d-flex flex-wrap px-2">
                      <router-link class="nav-link" :to="{ name: 'MenuGrilledCheese' }" @click.native="closeMenu">
                        <font-awesome-icon class="fa-lg" :icon="['fal', 'sandwich']" /> grilled cheese
                      </router-link>
                    </li>
                    <li class="nav-item d-flex flex-wrap px-2">
                      <router-link class="nav-link" :to="{ name: 'MenuVege' }" @click.native="closeMenu">
                        <font-awesome-icon class="fa-lg" :icon="['fal', 'seedling']" /> options végé
                      </router-link>
                    </li>
                    <li class="nav-item d-flex flex-wrap px-2">
                      <router-link class="nav-link" :to="{ name: 'MenuGaterie' }" @click.native="closeMenu">
                        <font-awesome-icon class="fa-lg" :icon="['fal', 'croissant']" /> nos gâteries
                      </router-link>
                    </li>
                    <!--<li class="nav-item d-flex flex-wrap ps-2">
                      <router-link class="nav-link pe-0" :to="{ name: 'MenuVins' }" @click.native="closeMenu">
                        <font-awesome-icon class="fa-1x" :icon="['fal', 'wine-glass-alt']" /> comptoir vin
                      </router-link>
                    </li>-->
                  </ul>
                  <div class="d-flex d-lg-none justify-content-center pt-2 mb-5 no-padding">
                    <div class="container no-padding">
                      <div class="col">
                        <NaviCta />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
  </header>
</template>

<script>
  import { Collapse } from 'bootstrap'
  import NaviCta from "@/components/menu/NaviCta.vue";

  export default {
    name: "doubleHeader",
    data() {
      return {
        bsCollapse: false
      }
    },
    components: {
        NaviCta,
    },
    computed: {
        getRouteName() {
            return this.$route.name;
        }
    },
    mounted() {
      this.bsCollapse = new Collapse(this.$refs['close-menu'], {
        toggle: false
      })
    },
    methods: {
        closeMenu() {
          this.bsCollapse.hide()
        },
        displayBigMenu() {
            this.$refs['navbar'].classList.value = this.$refs['navbar'].classList.value + ' hover';
        },
        hideBigMenu() {
            this.$refs['navbar'].classList.remove('hover');
        },
    },
    watch: {
        '$route'() {
            const navbarCollapse = document.querySelector("#navbar-collapse");
            if (navbarCollapse) {
                navbarCollapse.classList.remove("show");
            }
        }
    },
}
</script>

<style lang="scss" scoped>

.relative-block {
  position: relative;
}

.top-menu{
  position: absolute;
  z-index: 3000;
  right: var(--bs-gutter-x, 0.75rem);;
}

.navbar-brand {
    color: white;
    font-family: 'Righteous', cursive;
    font-size: 30px;
    margin-right: 1.5rem;
}

.navbar-brand:hover {
    color: $secondary !important;
}

.nav-link {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

ul>li:first-child {
    padding-left: 0 !important;
}
/*
ul>li {
    padding-left: 0.5rem;
}
*/

.no-padding {
  padding: 0 !important;
}

.nav-link:focus,
.nav-link:hover {
    color: $secondary !important;
    text-decoration: none;
}

.nav-item {
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.nav-item:focus,
.nav-item:hover {
    color: $secondary !important;
    text-decoration: none;
}

.nav-link.router-link-exact-active {
    color: $secondary;
    text-decoration: underline;
}

/*
.cta-icon {
    padding-right: 6px;
}
*/

  /*navbar red CTAs*/
  .cta_lb {
    color: white;
    background-color: $secondary;
    text-decoration: none;
  }

.cta_lb:focus,
.cta_lb:hover {
    color: white;
    background-color: $secondary;
    text-decoration: none;
}

.navbar-toggler {
    border-radius: 0;
}

.navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

.btn-outline-primary {
    border-color: white;
    color: white;
}

.btn-outline-primary:hover {
    background-color: white;
    color: $primary;
}

/**
 * Big Menu
 */
.big-menu {
    display: none;
    position: absolute;
    top: 2rem;
    left: 0;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    width: 335px;

    &:after {
        border-top: 1px solid white;
        content: "";
        position: absolute;
        top: 0.5rem;
        left: 0;
        width: 100%;
    }

    .child-link {
        color: white;
        text-decoration: none;
    }

    .child-link:focus,
    .child-link:hover {
        color: $secondary !important;
        text-decoration: none;
    }
}

.nav-item-menu {
    position: relative;
}

@include media-breakpoint-up(lg) {
    .nav-item-menu:hover {
        .big-menu {
            display: flex;
        }

        .nav-link {
            color: $secondary;
        }
    }
}

/**
 * Navbar Toggler
 */
.nav-icon {
    display: block;
    width: 30px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 5px;
        width: 100%;
        background: $secondary;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }
}

.nav-icon span:nth-child(1) {
    top: 0;
}

.nav-icon span:nth-child(2),
.nav-icon span:nth-child(3) {
    top: 10px;
}

.nav-icon span:nth-child(4) {
    top: 20px;
}

.navbar-toggler:not(.collapsed) .nav-icon span:nth-child(1) {
    color: $secondary;
    top: 10px;
    width: 0;
    left: 50%;
}

.navbar-toggler:not(.collapsed) .nav-icon span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.navbar-toggler:not(.collapsed) .nav-icon span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.navbar-toggler:not(.collapsed) .nav-icon span:nth-child(4) {
    top: 20px;
    width: 0;
    left: 50%;
}

/**
 * Mobile
 */
@include media-breakpoint-down(lg) {
    .nav-link {
        font-size: 24px;
        padding-bottom: 0.75rem;
        padding-top: 0.75rem;
    }
}

.cta_lb {
    color: white;
    background-color: $secondary;
    text-decoration: none;
    font-size: 1.2rem;
  }




</style>

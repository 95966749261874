import { render, staticRenderFns } from "./CoffeesAndPastries.vue?vue&type=template&id=0b97236a&scoped=true&"
import script from "./CoffeesAndPastries.vue?vue&type=script&lang=js&"
export * from "./CoffeesAndPastries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b97236a",
  null
  
)

export default component.exports
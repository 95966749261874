<template>
  <div id="menu-brunch" class="mt-5">
    <div class="container">
      <!-- Title -->
      <div class="row mb-5">
        <div class="col">
          <h1 class="mb-4">Un brunch gourmand qui fait voyager les gens de Verdun</h1>
          <p>
            Notre chef vous partage ses créations coups de coeur. Salé ou sucré, gourmand ou léger... le brunch à la base est un voyage à travers les saveurs et les souvenirs de son enfance et de ses nombreux voyage.  
          </p>
        </div>
      </div>

      <!-- Menu -->
      <div class="row">
        <div class="col-lg-8 col-xl-9">
          <div class="row">
            <div class="col">
              <MenuTwoColumnsSection class="border-bottom pb-4" :menu="menu.brunches" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <MenuOneColumnSection :hasSeparator="true"  :menu="menu.cocktails" />
            </div>
            <div class="col-md-6">
              <MenuOneColumnSection :hasSeparator="true" :menu="menu.desserts" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-3">
          <div class="row mb-n4">
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/brunch/double_sand.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/brunch/egg_cheese.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/brunch/hot_chicken.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/brunch/glasses.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <div class="menu-allergens">
                <div class="row mb-2">
                  <div class="col">
                    <font-awesome-icon class="fa-2x text-white" :icon="['fa', 'exclamation-triangle']" />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    Nos aliments peuvent contenir des allergènes. Faites-nous part de vos allergies.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/brunch/egg_cheese_diff.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/brunch/pancakes.jpg" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menu from "@/json/menu-brunch.json";
  import MenuOneColumnSection from "@/components/menu/MenuOneColumnSection.vue";
  import MenuTwoColumnsSection from "@/components/menu/MenuTwoColumnsSection.vue";

  export default {
    name: 'MenuBrunch',
    data() {
      return {
        menu
      }
    },
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    components: {
      MenuOneColumnSection,
      MenuTwoColumnsSection,
    },
  }
</script>

<style lang="scss" scoped>
#menu-brunch {
  background-image: url("../assets/pattern.svg");
  background-repeat: repeat;
  background-size: 150px 150px;
}

.title {
  color: $secondary;
  font-size: 22px;
  font-weight: bold;
}
</style>

<template>
  <div id="coffees" class="py-4">
    <div class="row">
        <div class="col-lg-6">
          <h2 class="pacman-title mb-0 text-white">cafés</h2> 
            <template v-for="(coffee, coffeeIndex) in coffees">
              <div class="d-flex justify-content-between" :key="coffeeIndex">
                <h3 v-html="coffee.name"></h3>
                <div class="h3" v-html="coffee.price + '&nbsp;$'"></div>
              </div>
            </template>       
            <div class="d-flex align-items-center justify-content-between">
              <h3>latté</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;3,50 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,25 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>mocha</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;4,00 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,75 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>matcha latté</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;4,00 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,75 $
                </span>
              </div>
            </div>
        </div>  
        <div class="col-lg-6">
          <h2 class="pacman-title mb-0 text-white">cafés et thés glacés</h2>        
            <div class="d-flex align-items-center justify-content-between">
              <h3>latté glacé</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;3,50 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,25 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>mocha glacé</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;4,00 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,75 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>matcha latté glacé</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;3,50 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,25 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>latté waikiki glacé</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;4,00 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,75 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>thé glacé</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;3,50 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,25 $
                </span>
              </div>
            </div>
            <h2 class="pacman-title mb-0 text-white">thés et tisanes</h2>        
            <div class="d-flex align-items-center justify-content-between">
              <h3>premium la base</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center">
                  4,25 $
                </span>
              </div>  
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>earl gray crème</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center">
                  3,00 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>london fog</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center me-4">
                  <small class="size">m</small>&nbsp;3,50 $
                </span>
                <span class="d-inline-flex align-items-center">
                  <small class="size">g</small>&nbsp;4,25 $
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h3>petit déjeuner anglais</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center">
                  3,00 $
                </span>
              </div>
            </div><div class="d-flex align-items-center justify-content-between">
              <h3>sencha</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center">
                  3,00 $
                </span>
              </div>
            </div><div class="d-flex align-items-center justify-content-between">
              <h3>jasmin premium</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center">
                  3,00 $
                </span>
              </div>
            </div><div class="d-flex align-items-center justify-content-between">
              <h3>citron gingembre</h3>
              <div class="h3">
                <span class="d-inline-flex align-items-center">
                  3,00 $
                </span>
              </div>
            </div>
            </div>
        </div>
        <hr>  
    </div>
</template>

<script>
  export default {
    name: 'Coffees',
    data() {
      return {
        coffees: [
          {
            "name": "espresso",
            "price": "2,75"
          },
          {
            "name": "espresso allongé",
            "price": "2,75"
          },
          {
            "name": "americano",
            "price": "2,75"
          },
          {
            "name": "macchiato",
            "price": "3,25"
          },
          {
            "name": "cortado",
            "price": "3,50"
          },
          {
            "name": "cappucino",
            "price": "3,50"
          },
          {
            "name": "flat white",
            "price": "3,50"
          },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>

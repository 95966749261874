<template>
  <div id="menu-weekend" class="mt-5">
    <div class="container">
      <div id="hero" class="carousel slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#hero" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-none d-md-block w-100" src="@/assets/home/retouche_la_base_Brunche_450px_haut.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/la_base_crepe_320px.jpg">
          </div>
          <!-- <div class="carousel-item">
            <img class="d-none d-md-block w-100" src="@/assets/home/carousel/2.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/2_mobile.jpg">
          </div>
          <div class="carousel-item">
            <a href="https://order.koomi.com/la-base-V0rjQMkeDy/fr">
              <img class="d-none d-md-block w-100" src="@/assets/home/carousel/3.png">
              <img class="d-block d-md-none w-100" src="@/assets/home/carousel/3_mobile.png">
            </a>
          </div> -->
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-8 mt-5">
          <h1 class="mb-4">Un brunch ensoleillé et créatif à Verdun</h1>
          <p>Notre chef vous partage ses créations coups de coeur. Salé ou sucré, gourmand ou léger...
            le brunch à la base est un voyage à travers les saveurs et les souvenirs de son enfance 
            et de ses nombreux voyages.
          </p>
        </div>
      </div>

      <!-- Menu -->
      <div class="row mt-5">
        <div class="col-md-6 px-sm-3 pe-lg-5">
          <MenuOneColumnSection :hasSeparator="true"  :menu="brunches.brunches" />
          <div class="row d-flex justify-content-center pb-sm-5 py-sm-2 pt-lg-4">
            <div class="col-sm-2 col-md-4">
              <div class="circle">
                <div >cocktail<br>mimosa<br >+ 10 $</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 px-sm-3 ps-lg-5">
          <Sapori :hasSeparator="true"  :menu="mambo.mambo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mambo from "@/json/menu-mambo.json";
  import brunches from "@/json/menu-brunch.json";
  import MenuOneColumnSection from "@/components/menu/MenuOneColumnSection.vue";
  import Sapori from "@/components/menu/Sapori"

  export default {
    name: 'MenuWeekEnd',
    data() {
      return {
        mambo,
        brunches,
      }
    },
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    components: {
      MenuOneColumnSection,
      Sapori
    },
  }
</script>

<style lang="scss" scoped>
.circle {
  background-color: $primary;
  border-radius: 50%;
  color: white;
  font-family: 'Righteous', cursive;
  font-size: 22px;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.circle > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@include media-breakpoint-down(sm) {
    .circle {
  border-radius: 50%;
  color: white;
  font-family: 'Righteous', cursive;
  font-size: 18px;
  padding-bottom: 30%;
  position: relative;
  width: 30%;
  text-align: center;
  margin: 0 auto 30px;
}

}



</style>

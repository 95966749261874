<template>
  <div id="menu-alpes" class="mt-5">
    <div class="container">
      <!-- Title -->
      <div class="row mb-4 title-container">
        <div class="col">
          <h1 class="d-md-inline">menu au sommet des alpes</h1>
        </div>
      </div>

      <!-- Paragraph -->
      <div class="row mb-5">
        <div class="col-lg-8 col-xl-9">
          <div class="fs-5">Cet hiver, La base vous amène en voyage au sommet des plus belles montagnes du monde. Découvrez un menu spécial pour toute la saison hivernale, en plus du menu régulier.</div>
        </div>
      </div>

      <!-- Menu -->
      <div class="row">
        <div class="col-lg-8 col-xl-9">
          <MenuOneColumnSection :hasSeparator="true" :menu="menu.burgers" />
          <MenuOneColumnSection :hasSeparator="true" :menu="menu.readyToEat" />
        </div>
        <div class="col-lg-4 col-xl-3">
          <div class="row mb-n4">
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/alpes/1.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/alpes/2.jpg" class="w-100" />
            </div>
            <!--
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/alpes/3.jpg" class="w-100" />
            </div>
            -->
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/alpes/4.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <div class="menu-allergens">
                <div class="row mb-2">
                  <div class="col">
                    <font-awesome-icon class="fa-2x text-white" :icon="['fa', 'exclamation-triangle']" />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    Nos aliments peuvent contenir des allergènes. Faites-nous part de vos allergies.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/alpes/5.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/alpes/6.jpg" class="w-100" />
            </div>
            <div class="col-sm-6 col-lg-12 mb-4">
              <img src="@/assets/menu/alpes/7.jpg" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menu from "@/json/menu-alpes.json";
  import MenuOneColumnSection from "@/components/menu/MenuOneColumnSection.vue";

  export default {
    name: 'MenuBrunch',
    data() {
      return {
        menu
      }
    },
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    components: {
      MenuOneColumnSection,
    },
  }
</script>

<style lang="scss" scoped>
#menu-alpes {
  background-image: url("../assets/pattern.svg");
  background-repeat: repeat;
  background-size: 150px 150px;
}
</style>

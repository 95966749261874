import Vue from 'vue'
import App from './App.vue'

/**
 * Bootstrap
 */
import "@/styles/bootstrap.scss";
import "bootstrap";


/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import from pro-regular library */
import {

} from "@fortawesome/pro-regular-svg-icons";

/* import icons from pro-solid library */
import {
  faBars,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faExclamationTriangle, 
 } from "@fortawesome/pro-solid-svg-icons";

 /* import icons from pro-light library */
 import {
  faCheeseburger, 
  faCroissant,
  faMapMarkerAlt, 
  faSandwich,
  faSeedling,
  faShoppingBasket, 
  faUserSecret,
  faWineGlass,
  faWineGlassAlt,
 } from "@fortawesome/pro-light-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* add icons to the library */
library.add(
  faBars,
  faCheeseburger,
  faCroissant,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faMapMarkerAlt,
  faExclamationTriangle,
  faSandwich,
  faSeedling,
  faShoppingBasket,
  faUserSecret,
  faWineGlass,
  faWineGlassAlt,
)

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

/**
 * Vue Check View
 */
import vueCheckView from "vue-check-view";

Vue.use(vueCheckView);

import router from "./router";

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("x-app-rendered"));
  }
}).$mount('#app')

<template>
  <div id="find-us" class="my-5">
    <div class="container title-container">
      <div class="row align-mb-n5">
        <div class="col-lg-8 mb-5">
          <div ref="gmaps" class="map"></div>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="row mb-n5">
            <div class="col-md-6 col-lg-12 mb-5">
              <!-- Logo -->
              <div class="row mb-4">
                <div class="col">
                  <img src="@/assets/logo.svg" width="65" />
                </div>
              </div>

              <!-- Address -->
              <div class="row mb-2">
                <div class="col">
                  <div class="fs-4 fw-bold text-primary">
                    5898 rue de Verdun,<br>
                    Verdun, Québec,<br>
                    H4H 1M4
                  </div>
                </div>
              </div>

              <!-- Phone -->
              <div class="row">
                <div class="col">
                  <a href="tel:5144652228" class="fs-4 fw-bold text-secondary">
                    514 465-2228
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-12 mb-5">
              <div class="row mb-4">
                <div class="col">
                  <h2 class="pacman-title">heures d'ouvertures</h2>
                </div>
              </div>
              <template v-for="(item, itemIndex) in openingHours">
                <div :key="itemIndex">
                  <div class="row">
                    <div class="col-4">
                      <span class="fs-5 fw-bold text-primary" v-html="item.day + ':'"></span>
                    </div>
                    <div class="col-8">
                      <span class="fs-5 fw-bold text-primary" v-html="item.hours"></span>
                    </div>
                  </div>
                  <hr class="my-2">
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FindUs',
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    data() {
      return {
        state: window.state,
        openingHours: [
          {
            "day": "lundi",
            "hours": "7h30 à 18h00",
          },
          {
            "day": "mardi",
            "hours": "7h30 à 18h00",
          },
          {
            "day": "mercredi",
            "hours": "7h30 à 18h00",
          },
          {
            "day": "jeudi",
            "hours": "7h30 à 21h30",
          },
          {
            "day": "vendredi",
            "hours": "7h30 à 21h30",
          },
          {
            "day": "samedi",
            "hours": "10h00 à 18h00",
          },
          {
            "day": "dimanche",
            "hours": "10h00 à 18h00",
          },
        ]
      }
    },
    mounted() {
      // Google Map is already ready
      if (this.state.initMap) {
        this.initializeMap();
      }
    },
    watch: {
      // we watch the state for changes in case the map was not ready when this
      // component is first rendered
      // the watch will trigger when `initMap` will turn from `false` to `true`
      "state.initMap"(isLoaded) {
        if (isLoaded) {
          this.initializeMap();
        }
      }
    },
    methods: {
      initializeMap() {
        const self = this;

        try {
          const icon = {
            url: require("@/assets/logo.svg"),
            scaledSize: { width: 40, height: 40, f: 'px', b: 'px' }
          };
          const markers = [
            {
              position: { lat: 45.448370, lng: -73.572400 },
              icon: icon,
            }
          ];
          const map = new window.google.maps.Map(self.$refs.gmaps, {
            // styles,
            center: new window.google.maps.LatLng(45.448370, -73.572400),
            mapId: "619dc318af38451e",
            streetViewControl: false,
            zoom: 15,
          });

          markers.map(x => {
            new window.google.maps.Marker({ ...x, map });
          });
        } catch (error) {
          console.error(error);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.map {
  min-height: 400px;
  height: 100%;
  width: 100%;
}
</style>

<template>
    <div id="menu" class="mt-5">
        <div class="container">
            <div class="row mb-5 title-container">
                <div class="col-lg-8 mt-5">
                    <h1 class="mb-4">Une gâterie en après-midi</h1>
                    <p>
                        Le péché mignon pardonné, c'est la gâterie de l'après-midi. Installez-vous à la base avec un bon livre, un bon café et une douceur parmi la sélection de viennoiseries et de desserts.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 px-sm-3 pe-lg-5">
                    <CoffeesAndPastries :menu="menu.pastries" />
                </div>
                <div class="col-lg-6 px-sm-3 pe-lg-5">
                    <IcedBeverages :menu="menuIced.icedBeverages" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import menu from "@/json/menu-coffee.json";
import menuIced from "@/json/menu-icedbeverages.json";
import CoffeesAndPastries from "@/components/menu/CoffeesAndPastries.vue";
import IcedBeverages from "@/components/menu/IcedBeverages.vue";

export default {
    name : "MenuGaterie",
    data() {
        return {
            menu,
            menuIced
        }
    },
    components : {
        CoffeesAndPastries,
        IcedBeverages,
    }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div id="menu-one-column-section" class="mb-sm-2 mb-lg-5">
    <!-- Icon -->
    <div class="row mb-1" v-if="isNight">
      <div class="col text-end">
        <img src="@/assets/icons/lantern.svg" class="me-3" width="30" />
      </div>
    </div>

    <!-- Title -->
    <div class="row mb-4" v-if="menu.title">
      <div class="col">
        <h2 class="pacman-title text-white">
          <span v-html="menu.title"></span>
        </h2>
      </div>
    </div>

    <!-- Paragraph -->
    <div class="row mb-4" v-if="menu.paragraph">
      <div class="col">
        <div class="" v-html="menu.paragraph"></div>
      </div>
    </div>

    <!-- Items -->
    <div class="menu" v-if="menu.items">
      <template v-for="(item, i) in menu.items">
        <div class="d-flex justify-content-between px-sm-2 px-md-3 px-lg-5 pb-5" :class="getClass(i, menu.items.length)" :key="i">
          <div>
            <div class="row" v-if="item.name">
              <div class="col">
                <h3 v-html="item.name"></h3>
              </div>
            </div>
            <div class="row" v-if="item.shortDescription || item.longDescription">
              <div class="col">
                <div class="menu-description d-flex flex-column pe-4">
                  <template v-if="item.shortDescription">
                    <span class="text-secondary" v-html="item.shortDescription"></span>
                  </template>
                  <span class="" v-html="item.longDescription" v-if="item.longDescription"></span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <!-- Price -->
            <div class="h5">
              <span v-html="item.price + '&nbsp;$'" v-if="item.price"></span>
              <span class="d-inline-flex align-items-center me-4" v-if="item.mediumSizePrice">
                <small class="size">m</small>
                <span>&nbsp;</span>
                <span v-html="item.mediumSizePrice + '&nbsp;$'"></span>
              </span>
              <span class="d-inline-flex align-items-center" v-if="item.largeSizePrice">
                <small class="size">g</small>
                <span>&nbsp;</span>
                <span v-html="item.largeSizePrice + '&nbsp;$'"></span>
              </span>
            </div>

            <!-- Vegetarian -->
            <div class="row mt-4" v-if="item.isVegetarian">
              <div class="col text-center">
                <img src="@/assets/icons/vegetarian.svg" width="50" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MenuOneColumnSection',
    props: [ "hasSeparator", "isNight", "menu" ],
    methods: {
      getClass(index, arraySize) {
        const classes = [];

        if (this.hasSeparator) {
          classes.push('menu-item');
        }

        if (!this.hasSeparator && index < arraySize - 1) {
          classes.push('mb-2');
        }

        return classes.join(" ");
      },
    }
  }
</script>

<style lang="scss" scoped>

.menu-description span {
  line-height: 1.6;
}

/*
.menu > .menu-item:not(:last-child) {
  border-bottom: 1px solid blue !important;
}
*/

.menu > .menu-item:last-child {
  border-bottom: none;
}


.mb-2 {
  border-style: none;
}

</style>

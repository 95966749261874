<template>
  <div id="menu-coffee" class="mt-5">
    <div class="container">
      <div id="hero" class="carousel slide" data-bs-ride="carousel">
        <!-- <div class="carousel-indicators">
          <button type="button" data-bs-target="#hero" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#hero" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-none d-md-block w-100" src="@/assets/home/carousel/coffee.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/coffee_mobile_320px.jpg">
          </div>
          <!-- <div class="carousel-item">
            <img class="d-none d-md-block w-100" src="@/assets/home/carousel/2.jpg">
            <img class="d-block d-md-none w-100" src="@/assets/home/carousel/2_mobile.jpg">
          </div>
          <div class="carousel-item">
            <a href="https://order.koomi.com/la-base-V0rjQMkeDy/fr">
              <img class="d-none d-md-block w-100" src="@/assets/home/carousel/3.png">
              <img class="d-block d-md-none w-100" src="@/assets/home/carousel/3_mobile.png">
            </a>
          </div> -->
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-8 mt-5">
          <h1 class="mb-4">La base, la destination café du matin à Verdun.</h1>
          <p>Commencez la journée avec un café de qualité. Notre flat white et nos lattés sont préparés avec un café torréfié localement 
              et un choix de lait de vache et alternatifs pour satisfaire tous vos goûts. 
          </p>
          <p>Cet été, découvrez nos cafés et thés glacés pour vous rafraîchir à tout moment de la journée et de la soirée.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 px-sm-3 pe-lg-5">
          <CoffeesAndPastries :hasSeparator="false" :menu="menu.coffees" />
        </div>
        <div class="col-md-6 px-sm-3 ps-lg-5">
          <IcedBeverages :hasSeparator="false" :menu="menuIced.icedBeverages" />
          <CoffeesAndPastries :hasSeparator="false" :menu="menu.teas" />
        </div>
      </div>
      <div class="row mt-5">
        <Milks :menu="menuMilks.milks" />
      </div>
      <hr class="mb-5">
      <div class="row mt-5 pt-4">
        <div class="col-md-6 px-sm-3 pe-lg-5">
          <CoffeesAndPastries :hasSeparator="false" :menu="menu.pastries" />
        </div>
        <div class="col-md-6 px-sm-3 ps-lg-5">
          <CoffeesAndPastries :hasSeparator="false" :menu="menu.breakfast" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menu from "@/json/menu-coffee.json";
  import menuIced from "@/json/menu-icedbeverages.json";
  import menuMilks from "@/json/menu-milks.json";
  import CoffeesAndPastries from "@/components/menu/CoffeesAndPastries.vue";
  import IcedBeverages from "@/components/menu/IcedBeverages.vue";
  import Milks from "@/components/menu/Milks.vue";

  export default {
    name: 'MenuCoffee',
    data() {
      return {
        menu,
        menuIced,
        menuMilks,
      }
    },
    metaInfo() {
      return {
        title: "Café cantine de Verdun",
      }
    },
    components: {
      CoffeesAndPastries,
      IcedBeverages,
      Milks,
    },
  }
</script>

<style lang="scss" scoped>
.circle {
  background-color: $primary;
  border-radius: 50%;
  color: white;
  font-family: 'Righteous', cursive;
  font-size: 22px;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.circle > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.carousel-inner {
  margin-top: 170px;
}

</style>

<template>
  <div id="menu-two-columns-section" class="mb-5">
    <div class="row mb-1" v-if="isNight">
      <div class="col text-end">
        <img src="@/assets/icons/lantern.svg" class="me-3" width="30" />
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <h2 class="pacman-title text-white" v-html="menu.title"></h2>
      </div>
    </div>

    <div class="row align-items-start">
      <template v-for="(item, i) in menu.items">
        <div class="col-md-6" :key="i">
          <div class="d-flex menu-item">
            <div class="me-3">
              <div class="row" v-if="item.name">
                <div class="col">
                  <h3 v-html="item.name"></h3>
                </div>
              </div>
              <div class="row" v-if="item.shortDescription || item.longDescription">
                <div class="col">
                  <div class="menu-description">
                    <template v-if="item.shortDescription">
                      <span class="text-secondary" v-html="item.shortDescription"></span>
                      <span>&nbsp;</span>
                    </template>
                    <span class="" v-html="item.longDescription" v-if="item.longDescription"></span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row" v-if="item.price">
                <div class="col text-center">
                  <template v-if="typeof(item.price) === 'string'">
                    <div class="h3" v-html="item.price + '&nbsp;$'"></div>
                  </template>
                  <template v-else-if="typeof(item.price) === 'object'">
                    <div class="h3">
                      <span class="d-inline-flex align-items-center me-4" v-if="item.price.small">
                        <small class="size">s</small>&nbsp;<span v-html="item.price.small + '&nbsp;$'"></span>
                      </span>
                      <span class="d-inline-flex align-items-center" v-if="item.price.large">
                        <small class="size">g</small>&nbsp;<span v-html="item.price.large + '&nbsp;$'"></span>
                      </span>
                    </div>
                  </template>
                </div>
              </div>
              <div class="row mt-2" v-if="item.isVegetarian">
                <div class="col text-center">
                  <img src="@/assets/icons/vegetarian.svg" width="50" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MenuTwoColumnsSection',
    props: [ "isNight", "menu" ],
  }
</script>

<style lang="scss" scoped>
// Desktop:
@include media-breakpoint-up(lg) {
  #menu-two-columns-section .col-md-6:last-child .menu-item,
  #menu-two-columns-section .col-md-6:nth-last-child(2) .menu-item {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

// Mobile:
@include media-breakpoint-down(lg) {
  #menu-two-columns-section .col-md-6:last-child .menu-item {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>

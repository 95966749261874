<template>
  <div id="app" :class="[$route.name.includes('MenuNight') ? 'dark-theme' : 'light-theme']">
    <DoubleHeader />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import DoubleHeader from "@/components/navigation/DoubleHeader.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    DoubleHeader, Footer,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

@import "@/styles/navbar.scss";
@import "@/styles/pacman.scss";
@import "@/styles/pacman-dark.scss";
@import "@/styles/theme.scss";

html {
  font-size: 14px;
}

#app {
  font-family: "Mulish", sans-serif;
}

h1, .h1 {
  font-size: 32px !important;
}

h1 + p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #34322E;
}

p + p {
  font-size: 1.2rem;
  line-height: 1.7;
  color: #34322E;
}

p.intro {
  font-size: 1.2rem !important;
}

h2, .h2 {
  font-size: 24px !important;
}

h3, .h3 {
  font-size: 22px !important;
}

h3.price {
  color: $dark !important;
}

h4, .h4 {
  font-size: 18px !important;
}

h5, .h5 {
  font-size: 18px !important;
  color: #34322E !important;
  font-family: "Mulish", sans-serif !important;
}

h6.addedExtra {
  font-family: 'Mullish', sans-serif;
  color: $dark;
  line-height: 1;
}

hr {
  background-color: $lighter !important;
  opacity: 1 !important;
}

small.size {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border: 2px solid $dark;
  border-radius: 50%;
  text-transform: uppercase;
}

.carousel-inner {
  margin-top: 170px;
}

@include media-breakpoint-down(sm) {
  h5, .h5 {
    font-size: 1rem !important;
    color: #34322E !important;
    font-family: "Mulish", sans-serif !important;
  }
  small.size {
    font-size: 12px;
    height: 20px;
    width: 20px;
  }
  h6.addedExtra {
    font-size: 0.8rem !important;
  }

  h3, .h3 {
    font-size: 1.4rem !important;
  }

  p.intro {
    font-size: 1rem !important;
  }

  .carousel-inner {
    margin-top: 155px; 
  }
}

</style>
